// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-search-results-js": () => import("/Applications/MAMP/htdocs/shifra-new/src/templates/searchResults.js" /* webpackChunkName: "component---src-templates-search-results-js" */),
  "component---src-components-home-home-js": () => import("/Applications/MAMP/htdocs/shifra-new/src/components/Home/Home.js" /* webpackChunkName: "component---src-components-home-home-js" */),
  "component---src-templates-about-page-js": () => import("/Applications/MAMP/htdocs/shifra-new/src/templates/aboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-components-clinic-locations-clinic-locations-js": () => import("/Applications/MAMP/htdocs/shifra-new/src/components/ClinicLocations/ClinicLocations.js" /* webpackChunkName: "component---src-components-clinic-locations-clinic-locations-js" */),
  "component---src-templates-text-page-js": () => import("/Applications/MAMP/htdocs/shifra-new/src/templates/textPage.js" /* webpackChunkName: "component---src-templates-text-page-js" */),
  "component---src-templates-navigation-page-js": () => import("/Applications/MAMP/htdocs/shifra-new/src/templates/navigationPage.js" /* webpackChunkName: "component---src-templates-navigation-page-js" */),
  "component---src-templates-content-page-js": () => import("/Applications/MAMP/htdocs/shifra-new/src/templates/contentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-pages-404-js": () => import("/Applications/MAMP/htdocs/shifra-new/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Applications/MAMP/htdocs/shifra-new/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

